@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

* {
  scroll-behavior: smooth;
}

.gradient-text {
  position: absolute;

  font-family: Arial, Helvetica, sans-serif;

  background: linear-gradient(
    180deg,
    #0193dc 0%,
    rgba(1, 147, 220, 0.51) 66.67%
  );

  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.slideTrack {
  display: flex;
  width: calc(250px * 53);
  animation: scroll 100s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 53));
  }
}

.headingDecoration::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    height: 2px;
}

.force-font {
  font-family: "Poppins", sans-serif !important;
}
